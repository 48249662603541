import React, { useEffect } from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { isFieldForListingType } from '../../util/fieldHelpers';

import { Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const {
    publicData,
    metadata = {},
    listingFieldConfigs,
    isFieldForCategory,
    intl,
    category,
    subCategory,
    existingListingFields,
    isMatchingSet,
    desiredMatchingSetData,
    handleNavigatetoSearch
  } = props;

  if (!publicData || !listingFieldConfigs) {
    return null;
  }

  const dontShowinCustomFields = ["condition", "carrier"];

  useEffect(() =>{
    if(isMatchingSet){
      dontShowinCustomFields.push("clothsSize", "shoeSize");
    }
  }, [isMatchingSet]);

  return existingListingFields.length > 0 ? (
    <section className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <div className={css.detailsInfo}>

        {/* matching Set data here... */}
        {isMatchingSet && desiredMatchingSetData.map((setData, index) =>(
          <li key={index} className={css.detailsRow} onClick={() => handleNavigatetoSearch('Size', { key: setData.filterType, value: setData.size })}>
            <span><FormattedMessage id='SectionDetailsMaybe.matchingSetItem' values={{ itemNum: index + 1 }}/></span>
            <b>{`${setData.type} ${intl.formatMessage({ id: "SectionDetailsMaybe.iconStanding" })} ${setData.size}`}</b>
          </li>
        ))}

        <li>
          <span><FormattedMessage id='SectionDetailsMaybe.CategoryText' /></span>
          <b onClick={() => handleNavigatetoSearch("category", { c1: category?.name })} className={css.category}>{category?.name}</b>
        </li>
        {!isMatchingSet ?
          <li><span><FormattedMessage id='SectionDetailsMaybe.subcategoryText' /></span>
            <b onClick={() => handleNavigatetoSearch("subcategory", { c1: category?.name, c2: subCategory?.name })} className={css.category}>{subCategory?.name}</b>
          </li> : null}
        
        {existingListingFields.filter(field => !dontShowinCustomFields.includes(field.key)).map(detail => (
          <li key={detail.key} className={css.detailsRow}>
            <span>{detail.showLabel}</span>
            <b onClick={() => handleNavigatetoSearch(detail.showLabel, {value: detail.label, c2: subCategory?.name, key: detail.key })} className={css.category}>{detail.label}</b>
          </li>
        ))}

      </div>
    </section>
  ) : null;
};

export default SectionDetailsMaybe;
