import React from 'react';

// Utils
import { PENDING_APPROVAL, SCHEMA_TYPE_MULTI_ENUM, SCHEMA_TYPE_TEXT } from '../../util/types';
import {
  isFieldForCategory,
  pickCategoryFields,
  pickCategoryFieldsForListingPage,
  pickCustomFieldProps,
  isFieldForListingType,
} from '../../util/fieldHelpers.js';

import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import { FormattedMessage } from 'react-intl';

import css from './ListingPage.module.css'
import { createResourceLocatorString } from '../../util/routes.js';

/**
 * Renders custom listing fields.
 * - SectionDetailsMaybe is used if schemaType is 'enum', 'long', or 'boolean'
 * - SectionMultiEnumMaybe is used if schemaType is 'multi-enum'
 * - SectionTextMaybe is used if schemaType is 'text'
 *
 * @param {*} props include publicData, metadata, listingFieldConfigs, categoryConfiguration
 * @returns React.Fragment containing aforementioned components
 */
const CustomListingFields = props => {
  const { publicData, metadata, listingFieldConfigs, categoryConfiguration, history, routeConfiguration, listing } = props;
  const { categoryLevel1, matchingSets=[] } = publicData || {};

  const { key: categoryPrefix, categories: listingCategoriesConfig } = categoryConfiguration;
  const categoriesObj = pickCategoryFieldsForListingPage(publicData, categoryPrefix, 1, listingCategoriesConfig);
  const matchingSetSubcategories = listingCategoriesConfig && listingCategoriesConfig.find(cate => cate.id == categoryLevel1)?.subcategories;
  const clothSize = listingCategoriesConfig && listingFieldConfigs.find(element => element.key == "clothsSize")?.enumOptions;
  const shoeSize = listingCategoriesConfig && listingFieldConfigs.find(element => element.key == "shoeSize")?.enumOptions;
  const combinedSizeData = [...clothSize, ...shoeSize];
  const desiredMatchingSetData = matchingSets.map(set =>{
    const item = matchingSetSubcategories && matchingSetSubcategories.find(element => element.id == set.type)?.name;
    const size = combinedSizeData && combinedSizeData.find(element => element.option == set.size)?.label;
    const filterType = set.type == "shoes" ? "shoeSize" : "clothsSize";
    return { size, type: item, filterType };
  });
  const currentCategories = Object.values(categoriesObj);
  const isMatchingSet = categoryLevel1 == "matchingSet";

  const isFieldForSelectedCategories = fieldConfig => {
    const isTargetCategory = isFieldForCategory(currentCategories, fieldConfig);
    return isTargetCategory;
  };

  const propsForCustomFields =
    pickCustomFieldProps(
      publicData,
      metadata,
      listingFieldConfigs,
      'listingType',
      isFieldForSelectedCategories
    ) || [];
    
  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType = isFieldForListingType(listingType, config);
    const isTargetCategory = isFieldForCategory(config);

    const { isDetail, label } = showConfig;
    // const publicDataValue = publicData[key];
    const publicDataValue = (Object.keys(publicData).length && publicData[key] && Object.keys(publicData[key]).length) ? publicData[key] : null;
    // const metadataValue = metadata[key];
    const metadataValue = Object.keys(publicData).length && metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && isTargetCategory && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue.option === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, showLabel: label, ...value })
        : schemaType === 'boolean'
          ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
          : schemaType === 'long'
            ? filteredConfigs.concat({ key, value, label, valueKey: optionConfig?.option })
            : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFieldConfigs.reduce(pickListingFields, []);
  const conditionField = existingListingFields.filter(field => field.key == "condition").reduce((acc, curr)=>{
    return Object.assign(acc, { ["key"]: curr.key, ["label"]: curr.label, ["value"]: curr.option });
  }, {});

  const getConditionStatementfromValue = key =>{
    switch(key){
      case "unwornTags":
        return <FormattedMessage id='CustomListingField.unwornTagsText' />;
      case "unwornnoTags":
        return <FormattedMessage id='CustomListingField.unwornnoTagsText' />;
      case "excellent":
        return <FormattedMessage id='CustomListingField.excellentText' />;
      case "veryGood":
        return <FormattedMessage id='CustomListingField.veryGoodText' />;
      case "good":
        return <FormattedMessage id='CustomListingField.goodText' />;
    }
  };

  const handleNavigatetoSearch = (type, data) =>{
    if(type == "category"){
      history.push(`/search?refinementList%5BpublicData.categoryLevel1.Label%5D%5B0%5D=${data?.c1}`);
    } else if(type == "subcategory"){
      history.push(`/search?refinementList%5BpublicData.categoryLevel2.Label%5D%5B0%5D=${data?.c1} > ${data?.c2}`);
    } else if(type == "Gender"){
      history.push(`/search?refinementList%5BpublicData.gender.label%5D%5B0%5D=${data?.value}`);
    } else if(type == "Size"){ 
      history.push(`/search?refinementList%5BpublicData.${data.key}.label%5D%5B0%5D=${data?.value}`)
    }
  };

  // const listingState = (listing && listing.id && listing.attributes.state) || "";
  // const isPendinApprovalState = listingState == PENDING_APPROVAL;

  return (
    <>
    {/* Details */}
      <SectionDetailsMaybe
        {...props}
        isFieldForCategory={isFieldForSelectedCategories}
        category={categoriesObj?.categoryLevel1}
        subCategory={categoriesObj?.categoryLevel2}
        existingListingFields={existingListingFields}
        desiredMatchingSetData={desiredMatchingSetData}
        isMatchingSet={isMatchingSet}
        handleNavigatetoSearch={handleNavigatetoSearch}
      />

      {/* Condition */}
      <div>
        <h4 className={css.conditionTitle}>{conditionField.label}</h4>
        <p className={css.ConditionDetials}>{getConditionStatementfromValue(conditionField.value)}</p>
      </div>

      {/* Additional Details */}
      <h4 className={css.additionalDetails}>
        <FormattedMessage id='CustomListingFields.AdditionalDetailsText' />
      </h4>
      <ul className={css.sectionListWrapper}>
      {propsForCustomFields.filter(fields => fields?.selectedOptions?.length).map((customFieldProps, index) => {
        const { schemaType, ...fieldProps } = customFieldProps;
        return schemaType === SCHEMA_TYPE_MULTI_ENUM ? (
            <li className={css.sectionList} key={index}>
            <SectionMultiEnumMaybe {...fieldProps} />
            </li>
        ) : schemaType === SCHEMA_TYPE_TEXT ? (
          <SectionTextMaybe {...fieldProps} key={index}/>
        ) : null;
      })}
      </ul>
    </>
  );
};

export default CustomListingFields;
